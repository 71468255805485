/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useCallback } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { trTR } from "@mui/x-date-pickers/locales";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/landing-pages/coworking";
import PresentationTarot from "layouts/pages/landing-pages/tarota-sor";

// Material Kit 2 PRO React routes
import routes from "routes";
import SignIn from "layouts/authentication/sign-in/basic";
import SignUp from "layouts/authentication/sign-up/cover";
import useAuth from "hooks/useAuth";
import useQuestion from "hooks/useQuestion";
import RequireAuth from "RequireAuth";
import { getPendingQuestions, getMyQuestions } from "api/RestHelper";
import { isSignedIn } from "auth";

export default function App() {
  let navigate = useNavigate();

  const { pathname } = useLocation();
  const { authState, signIn, signOut } = useAuth();
  const { user } = authState;

  const { setMyQuestion, setPendingQuestion } = useQuestion();

  const init = useCallback(
    (user) => {
      if (user.roles.includes("user")) {
        getMyQuestions(user.token, (r) => setMyQuestion(r));
      }
      if (user.roles.includes("fortuneTeller")) {
        getPendingQuestions(user.token, (r) => setPendingQuestion(r));
      }
    },
    [user]
  );
  const checkSession = (u) => {
    if (u) {
      // @ts-ignore
      let seconds = Math.abs(new Date().getTime() - new Date(u.lastSignInTime).getTime()) / 1000;
      if (seconds > 3600) {
        signOut();
        //navigate("/giris", { replace: true });
        return false;
      }
      return true;
    }
  };
  useEffect(() => {
    if (user !== undefined) {
      init(user);
    } else {
      const { user, signedIn } = isSignedIn();
      if (user && signedIn) {
        const sessionIn = checkSession(user);
        if (sessionIn) {
          signIn(user);
          init(user);
          navigate("/sorular", { replace: true });
        }
      } else {
        //navigate("/giris", { replace: true });
      }
    }
  }, [user]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.requireAuth) {
          return (
            <Route
              key={route.key}
              path={route.route}
              element={<RequireAuth>{route.component}</RequireAuth>}
            />
          );
        } else {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <CssBaseline />
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<Presentation />} />
          <Route path="/tarato-sor-giris" element={<PresentationTarot />} />
          <Route path="/giris" element={<SignIn />} />
          <Route path="/kayit" element={<SignUp />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
