/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Kit 2 PRO React components
import React, { useState } from "react";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
// Images
import Breadcrumbs from "examples/Breadcrumbs";
import Grid from "@mui/material/Grid";

import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { addAnswer } from "api/RestHelper";
import useAuth from "hooks/useAuth";
import useQuestion from "hooks/useQuestion";
import CircularProgress from "@mui/material/CircularProgress";

function Question({ item, setSelectedQuestion }) {
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const { authState } = useAuth();
  const { removePendingQuestion } = useQuestion();
  const { user } = authState;

  const sendAnswer = (e) => {
    e.preventDefault();
    setLoading(true);
    addAnswer(user.token, answer, item.id, () => {
      setSelectedQuestion(undefined);
      removePendingQuestion(item.id);
      setLoading(false);
    });
  };
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <MKBox component="section" py={2}>
      <Breadcrumbs
        routes={[
          {
            label: "Sorular",
            button: () => {
              setSelectedQuestion(undefined);
            },
          },
        ]}
      />
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={10}
          lg={7}
          mx="auto"
          mb={{ xs: 0, md: 6 }}
          textAlign="center"
        >
          <Grid item xs={12}>
            <MKTypography variant="h3" mb={1}>
              {item.question.questionText}
            </MKTypography>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="body2" color="text">
              {item.question.metadata.createdBy.user.firstname +
                " " +
                item.question.metadata.createdBy.user.lastname +
                " - " +
                new Date(item.question.metadata.createdBy.date).toLocaleString("tr-TR", {
                  dateStyle: "full",
                  timeStyle: "short",
                })}
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
          <MKBox
            onSubmit={sendAnswer}
            width="100%"
            component="form"
            method="post"
            autocomplete="off"
          >
            <MKBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MKInput
                    value={answer}
                    onChange={(event) => setAnswer(event.target.value)}
                    label="Cevap"
                    multiline
                    fullWidth
                    rows={6}
                  />
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" xs={12} my={6}>
                <MKButton type="submit" variant="gradient" color="info">
                  Cevapla
                </MKButton>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Question;
