/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

// Authentication pages components
import BasicLayout from "pages/Authentication/components/BasicLayout";

// Images
import bgImage from "assets/PhotoReal_tarot_question_4.jpg";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import useLoader from "hooks/useLoader";
import useAlert from "hooks/useAlert";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../../firebaseConfig";
import { getUser } from "api/RestHelper";
import useAuth from "hooks/useAuth";
import MKTypography from "components/MKTypography";
import { Link } from "react-router-dom";

function SignInBasic() {
  const navigate = useNavigate();
  const { signIn } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { setLoader, closeLoader } = useLoader();
  const { setAlert } = useAlert();

  const onSubmit = (e) => {
    e.preventDefault();
    setLoader({ open: true });

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        closeLoader();
        // Signed in
        const user = userCredential.user;
        // @ts-ignore
        localStorage.setItem("SIHIR_DEFTERI", user.accessToken);

        getUser(user.accessToken, user.uid, (response) => {
          // @ts-ignore
          signIn({ ...response.user, token: user.accessToken, uid: user.uid });
          navigate("/sorular", { replace: true });
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        closeLoader();
        setAlert({
          open: true,
          alertDetail: {
            color: "error",
            title: "Hata",
            content: "Giriş Yapılamadı, Email ve Şifrenizi kontrol edin. ",
            icon: "warning",
          },
        });
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form" onSubmit={onSubmit}>
            <MKBox mb={2}>
              <MKInput
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                type="email"
                label="Email"
                fullWidth
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                type="password"
                label="Password"
                fullWidth
              />
            </MKBox>

            <MKBox mt={4} mb={1}>
              <MKButton type="submit" variant="gradient" color="info" fullWidth>
                Giriş Yap
              </MKButton>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Hesabınız yok mu?{" "}
                <MKTypography
                  component={Link}
                  to="/kayit"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Hesap oluştur
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </BasicLayout>
  );
}

export default SignInBasic;
