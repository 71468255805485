import { useState, useEffect } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import image1 from "assets/canva/question/1.png";
import image10 from "assets/canva/question/10.png";
import image2 from "assets/canva/question/2.png";
import image3 from "assets/canva/question/3.png";
import image4 from "assets/canva/question/4.png";
import image5 from "assets/canva/question/5.png";
import image6 from "assets/canva/question/6.png";
import image7 from "assets/canva/question/7.png";
import image8 from "assets/canva/question/8.png";
import image9 from "assets/canva/question/9.png";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import InfoBackgroundCard from "examples/Cards/BackgroundCards/InfoBackgroundCard2";
// Images
import useAuth from "hooks/useAuth";
import useQuestion from "hooks/useQuestion";
import CircularProgress from "@mui/material/CircularProgress";
import Answer from "../Answer";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import { createQuestion } from "api/RestHelper";

function UserDashoard() {
  const [isAskQuestionButtonDisabled, setIsAskQuestionButtonDisabled] = useState();

  const [loading, setLoading] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [questionText, setQuestionText] = useState("");

  const { authState } = useAuth();
  const { user } = authState;
  const { questionState, addMyQuestion } = useQuestion();
  const { myQuestions, myQuestionsLoading } = questionState;
  const [questions, setQuestions] = useState([]);

  const [hasQuestion, setHasQuestion] = useState(false);
  useEffect(() => {
    if (myQuestions.length > 0) {
      const today = new Date();
      const todaysQuestions = myQuestions.filter((q) => {
        const questionDate = new Date(q.question.metadata.createdBy.date);
        const isToday = today.toDateString() == questionDate.toDateString();
        return isToday;
      });
      setHasQuestion(todaysQuestions.length > 0);

      setQuestions(
        myQuestions.sort(function (a, b) {
          if (a.answer === undefined && b.answer === undefined) {
            return a.question?.metadata.createdBy.date < b.question?.metadata.createdBy.date
              ? -1
              : 1;
          } else {
            if (a.answer !== undefined && b.answer !== undefined) {
              if (a.seenInfo !== undefined && b.seenInfo !== undefined) {
                return 1;
              } else if (a.seenInfo === undefined && b.seenInfo !== undefined) {
                return -1;
              } else if (a.seenInfo !== undefined && b.seenInfo === undefined) {
                return 0;
              }
            } else if (a.answer !== undefined && b.answer === undefined) {
              return 1;
            } else {
              return -1;
            }
          }
        })
      );
    }
  }, [myQuestions]);
  if (user === undefined) {
    return null;
  }
  if (myQuestionsLoading || loading) {
    return <CircularProgress />;
  }
  if (selectedQuestion) {
    return <Answer item={selectedQuestion} setSelectedQuestion={setSelectedQuestion} />;
  }
  const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10];

  const getcolor = (item) => {
    if (item.answer !== undefined) {
      if (item.seenInfo && item.seenInfo.seen) {
        return "dark";
      } else {
        return "warning";
      }
    } else {
      return "light";
    }
  };
  const getCaption = (item) => {
    if (item.answer !== undefined) {
      return "Cevaplandı";
    } else {
      return "Cevap Bekleniyor";
    }
  };
  const sendQuestion = (e) => {
    e.preventDefault();
    setLoading(true);
    createQuestion(user.token, { questionText: questionText }, (q) => {
      setQuestionText("");
      setIsAskQuestionButtonDisabled(false);
      addMyQuestion(q.data);
      setLoading(false);
    });
  };

  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          justifyContent="center"
          mx="auto"
          mb={6}
        >
          <MKBox py={2} px={6} textAlign="center">
            {/* <MKBox
            width="4rem"
            height="4rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
            variant="gradient"
            bgColor="info"
            color="white"
            shadow="md"
            borderRadius="lg"
            mx="auto"
          >
            <Icon fontSize="medium">person</Icon>
          </MKBox> */}
            <MKTypography variant="h2" mt={2} mb={1}>
              Merhaba
            </MKTypography>
            <MKTypography variant="body2" color="text">
              Merak ettiğin bir konuda rehberlik için buradayım! Hayatına ışık tutacak tek bir soru
              sorabilirsin. Gökyüzünden cevap almak için sorunu yaz ve tarot kartlarının sihirli
              dünyasına adım at!
            </MKTypography>
          </MKBox>
          <Container>
            <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}>
              {hasQuestion ? (
                <Grid item xs={12} lg={12} sx={{ mt: 2 }}>
                  <MKTypography
                    display="block"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mb={1}
                  >
                    Günlük bir soru sorma hakkınız vardır
                  </MKTypography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} lg={12} sx={{ mt: 2 }}>
                    <MKTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      mb={1}
                    >
                      Sorunuzu yazın
                    </MKTypography>
                    <MKInput
                      disabled={hasQuestion}
                      value={questionText}
                      onChange={(event) => setQuestionText(event.target.value)}
                      type="text"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} lg={12} sx={{ mt: 1 }}>
                    <MKButton
                      disabled={isAskQuestionButtonDisabled}
                      onClick={sendQuestion}
                      variant="gradient"
                      color="info"
                      fullWidth
                    >
                      Gönder
                    </MKButton>
                  </Grid>
                </>
              )}
            </Grid>
          </Container>
        </Grid>
        <Grid container spacing={3} mb={6}>
          <Grid container spacing={3} item xs={12} md={12} lg={12}>
            {questions.map((item) => {
              return (
                <Grid
                  onClick={() => setSelectedQuestion(item)}
                  key={item.question.id}
                  item
                  xs={12}
                  md={6}
                >
                  {/* <Link to="/sections/page-sections/blog-posts"> */}
                  <InfoBackgroundCard
                    color={getcolor(item)}
                    key={item.question.id}
                    image={images[Math.floor(Math.random() * 9)]}
                    icon="import_contacts"
                    contact={
                      item.question.metadata.createdBy.user.firstname +
                      " " +
                      item.question.metadata.createdBy.user.lastname
                    }
                    email={item.question.metadata.createdBy.user.email}
                    title={
                      item.question.questionText.length > 15
                        ? item.question.questionText.substring(0, 15) + "..."
                        : item.question.questionText
                    }
                    label={new Date(item.question.metadata.createdBy.date).toLocaleString("tr-TR", {
                      dateStyle: "full",
                      timeStyle: "short",
                    })}
                    caption={getCaption(item)}
                  />
                  {/* </Link> */}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default UserDashoard;
