/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { AuthProvider } from "providers/AuthProvider";
import { AlertProvider } from "providers/AlertProvider";
import { LoaderProvider } from "providers/LoaderProvider";
import { QuestionProvider } from "providers/QuestionProvider";
const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <AuthProvider>
      <AlertProvider>
        <LoaderProvider>
          <QuestionProvider>
            <App />
          </QuestionProvider>
        </LoaderProvider>
      </AlertProvider>
    </AuthProvider>
  </BrowserRouter>
);
