/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import team1 from "assets/sihirdefteri.jpg";
import mock1 from "assets/mock/1.png";
import mock2 from "assets/mock/2.png";
import mock3 from "assets/mock/3.png";
import mock4 from "assets/mock/4.png";
import mock5 from "assets/mock/5.png";
import SimpleImageCard from "examples/Cards/BlogCards/SimpleImageCard";

function Places() {
  const actionProps = {
    type: "internal",
    route: "/pages/landing-pages/coworking",
    color: "dark",
    label: "find more",
  };

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={9}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKTypography variant="h2" mb={1}>
            Tarot Yolculuğu: Özünüze Doğru Rehberlik
          </MKTypography>
          <MKTypography variant="body1" color="text">
            <br />
            Merakınızı besleyin ve içsel keşif yolculuğunuzu başlatın! Tarot Yolculuğu uygulamamız,
            size özgün ve derinlemesine rehberlik sunarak yaşamınızın önemli sorularına ışık
            tutuyor. <br />
            <br /> Tarot yorumcumuzun uzmanlığı ve tarot eğitimi sayesinde, günde bir soru hakkı ile
            sınırlı olan kullanıcılarımızın en değerli sorularına odaklanabiliyoruz. Tarot
            yorumcumuzun çektiği tarot kartları, kullanıcılarımızın sorularını güçlü semboller ve
            anlamlarla birleştirerek derinlemesine bir anlayış sunuyor. Her yanıt, farkındalığınızı
            artırmak ve içsel bilgeliğinize ulaşmanızı sağlamak için dikkatle hazırlanmıştır. <br />{" "}
            <br /> Ayrıca, Tarot yorumcumuzun danışmanlık hizmetleri ve tarot başlangıç eğitimleri
            ile kendi tarot yolculuğunuzu başlatmanıza ve derinleştirmenize olanak sağlıyoruz.
            Kendinizi keşfetmek ve içsel rehberliği deneyimlemek için Sihir Defteri Tarot
            uygulamasına katılın!
            <br />
            <br /> Sorularınızın cevaplarına ve içsel rehberliğinize doğru adım atmak için hemen
            uygulamamızı indirin ve başlayın.
          </MKTypography>
        </Grid>

        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleImageCard
                image={mock1}
                title="Campus 6"
                description="Website visitors today demand a frictionless user expericence. Applies to mobile applications too."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleImageCard
                image={mock2}
                title="Cozy Spots"
                description="If you're more passionate about founding a business than the business itself technology."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleImageCard
                image={mock3}
                title="Home Office"
                description="Website visitors today demand a frictionless user expericence — especially when using search."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleImageCard
                image={mock4}
                title="Virtual Office"
                description="If you're more passionate about founding a business than the business itself, you can fall into."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleImageCard
                image={mock5}
                title="Co-working Spaces"
                description="Smart money is just dumb money that's been through a crash. Business than the business itself."
                action={actionProps}
              />
            </MKBox>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={12} lg={12}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Sihir Defteri"
                position={{
                  color: "info",
                  label: "Tarot yorumcusu",
                  instagram: "https://www.instagram.com/sihir_defteri_/",
                  link: "https://www.shopier.com/ShowProductNew/storefront.php?shop=Sihir_defteri_&sid=a2taT2VYemUxdENGN1Z0UjBfLTFfIF8g",
                }}
                description="Pelin, çok yönlü bir bakış açısına ve derin bir öğrenme tutkusuna sahip olan bir bireydir. Hukuk alanındaki eğitimiyle akademik başarı elde etmiş olsa da, ilgi alanları sadece bu alanda sınırlı değildir. Astroloji, Tarot, Regresyon gibi metafizik ve ruhsal konulara olan ilgisi, onu farklı bakış açıları ve derin içgörülerle donatmıştır. Aynı zamanda Theta Healing, Reiki ve diğer enerji çalışmalarıyla da ruhsal dengeyi ve iyileşmeyi desteklemektedir. Bu çeşitli alanlardaki bilgi birikimi ve deneyimleriyle, insanların kişisel dönüşüm ve gelişim süreçlerine katkıda bulunmaktadır. Tutkulu ve meraklı doğasıyla, her zaman yeni bilgiler keşfetmeye ve insanların daha bütünsel bir yaşam sürmelerine yardımcı olmaya odaklanmıştır."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
