import React, { useState } from "react";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";
import { useNavigate } from "react-router-dom";
import { createUser } from "api/RestHelper";

// Images
import bgImage from "assets/PhotoReal_tarot_question_5.jpg";

function Cover() {
  const navigate = useNavigate();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    const user = {
      displayName: firstname + " " + lastname,
      firstname,
      lastname,
      roles: ["user"],
      email,
    };
    createUser(user, () => {
      navigate("/giris", { replace: true });
    });
  };
  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MKBox p={3}>
          <MKBox onSubmit={onSubmit} component="form" role="form">
            <MKBox mb={2}>
              <MKInput
                value={firstname}
                onChange={(event) => setFirstname(event.target.value)}
                type="text"
                label="İsim"
                fullWidth
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                value={lastname}
                onChange={(event) => setLastname(event.target.value)}
                type="text"
                label="Soyisim"
                fullWidth
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                type="email"
                label="Email"
                fullWidth
              />
            </MKBox>
            <MKBox mt={3} mb={1}>
              <MKButton type="submit" variant="gradient" color="info" fullWidth>
                Hesap oluştur
              </MKButton>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Zaten hesabınız var mı?{" "}
                <MKTypography
                  component={Link}
                  to="/authentication/sign-in/cover"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Giriş Yap
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
