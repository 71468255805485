/* eslint-disable react/jsx-key */
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InfoBackgroundCard from "examples/Cards/BackgroundCards/InfoBackgroundCard2";
import image1 from "assets/canva/question/1.png";
import image2 from "assets/canva/question/2.png";
import image3 from "assets/canva/question/3.png";
import image4 from "assets/canva/question/4.png";
import image5 from "assets/canva/question/5.png";
import image6 from "assets/canva/question/6.png";
import image7 from "assets/canva/question/7.png";
import image8 from "assets/canva/question/8.png";
import image9 from "assets/canva/question/9.png";
import image10 from "assets/canva/question/10.png";
// Images
import useAuth from "hooks/useAuth";
import useQuestion from "hooks/useQuestion";
import { useState } from "react";
import Question from "../Question";
import CircularProgress from "@mui/material/CircularProgress";

function FortuneTellerDashoard() {
  const [selectedQuestion, setSelectedQuestion] = useState();
  const { authState } = useAuth();
  const { questionState } = useQuestion();
  const { pendingQuestions, pendingQuestionsLoading } = questionState;
  const { user } = authState;
  if (user === undefined) {
    return null;
  }
  if (pendingQuestionsLoading) {
    return <CircularProgress />;
  }
  const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10];

  if (selectedQuestion) {
    return <Question item={selectedQuestion} setSelectedQuestion={setSelectedQuestion} />;
  }
  return (
    <MKBox component="section" py={2}>
      <Container>
        {/* <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          justifyContent="center"
          mx="auto"
          mb={6}
        >
          <MKBox py={2} px={6} textAlign="center">
            <MKTypography variant="h2" mt={2} mb={1}>
              Check out what&apos;s new
            </MKTypography>
            <MKTypography variant="body2" color="text">
              We get insulted by others, lose trust for those others. We get back freezes every
              winter
            </MKTypography>
          </MKBox>
        </Grid> */}
        <Grid container spacing={3} mb={6}>
          <Grid container spacing={3} item xs={12} md={12} lg={12}>
            {pendingQuestions.map((item) => {
              console.log(item);
              return (
                <Grid
                  onClick={() => setSelectedQuestion(item)}
                  key={item.question.id}
                  item
                  xs={12}
                  md={6}
                >
                  {/* <Link to="/sections/page-sections/blog-posts"> */}
                  <InfoBackgroundCard
                    key={item.question.id}
                    image={images[Math.floor(Math.random() * 9)]}
                    icon="import_contacts"
                    contact={
                      item.question.metadata.createdBy.user.firstname +
                      " " +
                      item.question.metadata.createdBy.user.lastname
                    }
                    email={item.question.metadata.createdBy.user.email}
                    title={
                      item.question.questionText.length > 15
                        ? item.question.questionText.substring(0, 15) + "..."
                        : item.question.questionText
                    }
                    label={new Date(item.question.metadata.createdBy.date).toLocaleString("tr-TR", {
                      dateStyle: "full",
                      timeStyle: "short",
                    })}
                  />
                  {/* </Link> */}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FortuneTellerDashoard;
