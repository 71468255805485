/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import team1 from "assets/sihirdefteri.jpg";
import numerology from "assets/Default_Numerology_1.jpg";
import tarot from "assets/Default_tarot_cards_on_deck_0.jpg";

import RaisedBlogCard from "examples/Cards/BlogCards/RaisedBlogCard";

function Places() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={9}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          {/* <MKTypography variant="h2" mb={1}>
            Tarot Yolculuğu: Özünüze Doğru Rehberlik
          </MKTypography> */}
          <MKTypography variant="body1" color="text" mb={1}>
            Sihir Defteri, mistik dünyanın kapılarını aralayan bir web uygulamasıdır. İçsel
            yolculuğunuzda size rehberlik ederek, tarot ve numeroloji gibi eski kehanet araçlarını
            kullanarak derinlemesine anlayışlar sunar. Her bir kartın ve sayının gizemli dilini
            çözerek, kendi iç dünyanızı keşfetmenize ve geleceğinizi şekillendirmenize yardımcı
            olur.
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={1}>
            Tarot, binlerce yıldır insanların ruhsal derinliklerini araştırmak için kullanılan güçlü
            bir araçtır. Sihir Defteri, bu gizemli kartların çeşitli kombinasyonlarını kullanarak
            size özgün bir bakış açısı sunar. Her kartın sembolizmi ve pozisyonunun derin
            analiziyle, içsel yolculuğunuzda önemli bir kılavuz olur.
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={1}>
            Numeroloji ise sayıların gizemli dünyasını keşfetmenizi sağlar. Sihir Defteri, doğum
            tarihiniz ve diğer kişisel bilgileriniz temelinde size özel numerolojik hesaplamalar
            yapar. Bu hesaplamalar, kişisel özelliklerinizi, yaşam döngülerinizi ve geleceğinizdeki
            potansiyelleri daha iyi anlamanıza yardımcı olur.
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={1}>
            Sihir Defteri, sizin için derinlemesine bir keşif yolculuğu sunar. Kendinizi daha iyi
            tanımanın ve potansiyelinizi keşfetmenin yanı sıra, hayatınızı daha bilinçli bir şekilde
            yönlendirmenize yardımcı olur. İçsel rehberliğinizle buluşmak ve kaderinizi
            şekillendirmek için bugün Sihir Defteri&apos;ne adım atın!
          </MKTypography>
        </Grid>

        <MKBox pt={6} pb={3} px={3}>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <Grid item xs={12} md={6} lg={6}>
              <MKBox mt={3}>
                <RaisedBlogCard
                  image={numerology}
                  title="Numeroloji"
                  description=""
                  action={{
                    type: "internal",
                    route: "/numeroloji",
                    color: "info",
                    label: "Daha fazlası için tıklayın",
                  }}
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MKBox mt={3}>
                <RaisedBlogCard
                  image={tarot}
                  title="Tarota Sor"
                  description=""
                  action={{
                    type: "internal",
                    route: "/tarota-sor",
                    color: "info",
                    label: "Daha fazlası için tıklayın",
                  }}
                />
              </MKBox>
            </Grid>
          </Grid>
        </MKBox>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={12} lg={12}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Sihir Defteri"
                position={{
                  color: "info",
                  label: "Tarot yorumcusu",
                  instagram: "https://www.instagram.com/sihir_defteri_/",
                  link: "https://www.shopier.com/ShowProductNew/storefront.php?shop=Sihir_defteri_&sid=a2taT2VYemUxdENGN1Z0UjBfLTFfIF8g",
                }}
                description="Pelin, çok yönlü bir bakış açısına ve derin bir öğrenme tutkusuna sahip olan bir bireydir. Hukuk alanındaki eğitimiyle akademik başarı elde etmiş olsa da, ilgi alanları sadece bu alanda sınırlı değildir. Astroloji, Tarot, Regresyon gibi metafizik ve ruhsal konulara olan ilgisi, onu farklı bakış açıları ve derin içgörülerle donatmıştır. Aynı zamanda Theta Healing, Reiki ve diğer enerji çalışmalarıyla da ruhsal dengeyi ve iyileşmeyi desteklemektedir. Bu çeşitli alanlardaki bilgi birikimi ve deneyimleriyle, insanların kişisel dönüşüm ve gelişim süreçlerine katkıda bulunmaktadır. Tutkulu ve meraklı doğasıyla, her zaman yeni bilgiler keşfetmeye ve insanların daha bütünsel bir yaşam sürmelerine yardımcı olmaya odaklanmıştır."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
