import { initializeApp, getApp } from "firebase/app";
import { initializeAuth, getAuth } from "firebase/auth";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDeSj4Fah70SLN-98pOLDKLX5J96TUD1Ds",
  authDomain: "testtme-tarot-soru-cevap.firebaseapp.com",
  projectId: "testtme-tarot-soru-cevap",
  storageBucket: "testtme-tarot-soru-cevap.appspot.com",
  messagingSenderId: "640128373401",
  appId: "1:640128373401:web:a4585175be1ef0225c5e08",
  measurementId: "G-FCVYR3PLFK",
};

const app = initializeApp(firebaseConfig);
const auth = initializeAuth(app);
// For more information on how to access Firebase in your project,
// see the Firebase documentation: https://firebase.google.com/docs/web/setup#access-firebase
export { app, auth, getApp, getAuth };
