/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Kit 2 PRO React components
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
// Images
import Breadcrumbs from "examples/Breadcrumbs";
import Grid from "@mui/material/Grid";

import MKTypography from "components/MKTypography";
import { seen } from "api/RestHelper";
import useAuth from "hooks/useAuth";
import useQuestion from "hooks/useQuestion";
import CircularProgress from "@mui/material/CircularProgress";

function Answer({ item, setSelectedQuestion }) {
  const [loading, setLoading] = useState(false);
  const { authState } = useAuth();
  const { updateQuestion } = useQuestion();
  const { user } = authState;

  useEffect(() => {
    const seenmethod = async () => {
      if (item.answer) {
        setLoading(true);
        seen(user.token, item.id, () => {
          setLoading(false);

          // navigation.navigate("Home");
          updateQuestion({ ...item, seenInfo: { seen: true } }, item.id);
        });
      } else {
        // navigation.navigate("Home");
      }
    };

    seenmethod();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <MKBox component="section" py={2}>
      <Breadcrumbs
        routes={[
          {
            label: "Sorular",
            button: () => {
              setSelectedQuestion(undefined);
            },
          },
        ]}
      />
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={10}
          lg={7}
          mx="auto"
          mb={{ xs: 0, md: 6 }}
          textAlign="center"
        >
          <Grid item xs={12}>
            <MKTypography variant="body2" mb={1}>
              {item.question.questionText}
            </MKTypography>
          </Grid>

          {item.answer && (
            <>
              <Grid item xs={12}>
                <MKTypography variant="h3" mb={1}>
                  {item.answer.answerText}
                </MKTypography>
              </Grid>
              <Grid item xs={12}>
                <MKTypography variant="body2" color="text">
                  {item.answer.metadata.createdBy.user.firstname +
                    " " +
                    item.answer.metadata.createdBy.user.lastname +
                    " - " +
                    new Date(item.answer.metadata.createdBy.date).toLocaleString("tr-TR", {
                      dateStyle: "full",
                      timeStyle: "short",
                    })}
                </MKTypography>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Answer;
