/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import DefaultFooter from "examples/Footers/SimpleFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import footerRoutes from "footer.routes";
import routes from "routes";

// Images
import useAuth from "hooks/useAuth";
import FortuneTellerDashoard from "pages/Questions/FortuneTellerDashboard";
import UserDashoard from "pages/Questions/UserDashboard";

function Questions() {
  const { authState } = useAuth();
  const { user } = authState;
  if (user === undefined) {
    return null;
  }

  return (
    <>
      <MKBox bgColor="white" shadow="sm" py={0.25}>
        <DefaultNavbar routes={routes} transparent relative center sticky />
      </MKBox>
      {user.roles.includes("user") ? <UserDashoard /> : <FortuneTellerDashoard />}

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Questions;
