/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function InfoBackgroundCard({ image, color, title, label, contact, email, caption }) {
  return (
    <Card
      sx={({
        palette: { gradients },
        functions: { rgba, linearGradient },
        borders: { borderRadius },
      }) => ({
        backgroundImage: `${linearGradient(
          rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.9),
          rgba(gradients[color] ? gradients[color].state : gradients.info.state, 0.9)
        )}, url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: borderRadius.xl,
        height: "100%",
        display: "grid",
        placeItems: "center",
      })}
    >
      {/* <MKBox width="100%" p={3}>
        <MKTypography variant="h3" color="white">
          {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
        </MKTypography>
      </MKBox>
       */}
      {caption && (
        <MKBox width="100%" p={3}>
          <MKTypography
            variant="caption"
            color={color === "light" ? "dark" : "white"}
            textTransform="uppercase"
            fontWeight="medium"
            textGradient
            sx={{ display: "block" }}
          >
            {caption}
          </MKTypography>
        </MKBox>
      )}

      <MKBox width="100%" p={3}>
        <MKTypography color={color === "light" ? "dark" : "white"} variant="h3">
          {title}
        </MKTypography>
      </MKBox>
      <MKBox width="100%" pt={1} pb={2} px={3} lineHeight={1}>
        <MKTypography color={color === "light" ? "dark" : "white"} variant="h4">
          {contact}
        </MKTypography>
        {email && (
          <MKTypography
            color={color === "light" ? "text" : "white"}
            variant="caption"
            fontWeight="bold"
            opacity={0.7}
          >
            {email + " ~ "}
          </MKTypography>
        )}
        {label && (
          <MKTypography
            color={color === "light" ? "text" : "white"}
            variant="caption"
            textTransform="uppercase"
            fontWeight="bold"
            opacity={0.7}
          >
            {label}
          </MKTypography>
        )}
      </MKBox>
    </Card>
  );
}

// Setting default values for the props of InfoBackgroundCard
InfoBackgroundCard.defaultProps = {
  label: "",
};

// Typechecking props for the InfoBackgroundCard
InfoBackgroundCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  email: PropTypes.string,
  contact: PropTypes.string,
  caption: PropTypes.string,
};

export default InfoBackgroundCard;
