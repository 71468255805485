import axios from "axios";

const getAxiosInstance = (token) => {
  const instance = axios.create({
    baseURL: "https://us-central1-testtme-tarot-soru-cevap.cloudfunctions.net/api",
    headers: { Authorization: "Bearer " + token },
  });

  return instance;
};

const getAxiosInstanceWithoutToken = () => {
  const instance = axios.create({
    baseURL: "https://us-central1-testtme-tarot-soru-cevap.cloudfunctions.net/api",
  });

  return instance;
};

export const createUser = (newUser, callback) => {
  getAxiosInstanceWithoutToken()
    .post("users", newUser)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getUser = (token, id, callback) => {
  getAxiosInstance(token)
    .get("users/" + id)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};
export const createQuestion = (token, newQuestion, callback) => {
  getAxiosInstance(token)
    .post("questions", newQuestion)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};
export const getPendingQuestions = (token, callback) => {
  getAxiosInstance(token)
    .get("questions/pending")
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};
export const getMyQuestions = (token, callback) => {
  getAxiosInstance(token)
    .get("questions/user")
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};
export const addAnswer = (token, answer, qid, callback) => {
  getAxiosInstance(token)
    .patch("questions/" + qid, { answer })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};
export const seen = (token, qid, callback) => {
  getAxiosInstance(token)
    .patch("questions/user/" + qid)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};
