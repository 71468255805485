/* eslint-disable react/prop-types */
import React from "react";
import { AUTH_CONTEXT_ERROR } from "../constants/Text";

export const questionContext = React.createContext({
  setPendingQuestion: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  setMyQuestion: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  addMyQuestion: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  updateQuestion: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  addPendingQuestion: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  removePendingQuestion: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  setInitial: () => {
    throw new Error(AUTH_CONTEXT_ERROR);
  },
  questionState: {
    myQuestions: [],
    myQuestionsLoading: true,
    pendingQuestions: [],
    pendingQuestionsLoading: true,
  },
});

const { Provider } = questionContext;

export const QuestionProvider = (props) => {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "SET_INITIAL":
          return { myQuestions: [], pendingQuestions: [] };
        case "SET_MY_QUESTION":
          return { ...prevState, myQuestions: action.questions, myQuestionsLoading: false };
        case "ADD_MY_QUESTION":
          return { ...prevState, myQuestions: [...prevState.myQuestions, action.question] };
        case "SEEN":
          return {
            ...prevState,
            myQuestions: prevState.myQuestions.map((q) => {
              if (q.id !== action.qid) {
                return q;
              } else {
                return action.question;
              }
            }),
          };
        case "ADD_PENDING_QUESTION":
          return {
            ...prevState,
            pendingQuestions: [...prevState.pendingQuestions, action.question],
          };
        case "REMOVE_PENDING_QUESTION":
          return {
            ...prevState,
            pendingQuestions: prevState.pendingQuestions.filter((q) => q.id !== action.qid),
          };
        case "SET_PENDING_QUESTION":
          return {
            ...prevState,
            pendingQuestions: action.questions,
            pendingQuestionsLoading: false,
          };
        default:
          return prevState;
      }
    },
    {
      myQuestions: [],
      myQuestionsLoading: true,
      pendingQuestions: [],
      pendingQuestionsLoading: true,
    }
  );

  const setInitial = () => {
    dispatch({
      type: "SET_INITIAL",
    });
  };
  const setMyQuestion = (questions) => {
    dispatch({
      type: "SET_MY_QUESTION",
      questions,
    });
  };
  const addMyQuestion = (question) => {
    dispatch({
      type: "ADD_MY_QUESTION",
      question,
    });
  };
  const updateQuestion = (question, qid) => {
    dispatch({
      type: "SEEN",
      question,
      qid,
    });
  };

  const addPendingQuestion = (question) => {
    dispatch({
      type: "ADD_PENDING_QUESTION",
      question,
    });
  };

  const removePendingQuestion = (qid) => {
    dispatch({
      type: "REMOVE_PENDING_QUESTION",
      qid,
    });
  };

  const setPendingQuestion = (questions) => {
    dispatch({
      type: "SET_PENDING_QUESTION",
      questions,
    });
  };

  return (
    <Provider
      value={{
        questionState: state,
        setPendingQuestion,
        setMyQuestion,
        addPendingQuestion,
        addMyQuestion,
        updateQuestion,
        removePendingQuestion,
        setInitial,
      }}
    >
      {props.children}
    </Provider>
  );
};
